import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import {stylesLayout} from './styles'
import { constants } from '../../../config/constants';
import ChangeLang from '../../../components/changeLang'
import './style.css'
import { Link } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';


  const Index = ({showBack = true, showFooterBack = true,children}) => {
    const {
      allmenu
    } = useSelector((state) => state.initLoad);
    

     const navigate = useNavigate();

    const modeDark = useSelector((state) => state.modeDark).modeDark;
    const styles = stylesLayout(modeDark);



    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [hash, setHash] = useState('');


    useEffect(() => {
      const hash = localStorage.getItem('hash');
      setHash(hash);
    }, []);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


      let responsiveDeviceNav;
      let responsiveDeviceChild;
      let iconMenu


      let openNavStyle = {
          left: '0px',
          width: '40%',
          transition: 'left 0.3s, width 0.3s',
      }
      
      if (windowWidth >= 1024) {
        responsiveDeviceNav = {};
        responsiveDeviceChild = {};
        iconMenu = {display: 'none'}
      } else if (windowWidth >= 768) {
        responsiveDeviceNav = {};
        responsiveDeviceChild = {};
        iconMenu = {display: 'none'}
      } else {
        responsiveDeviceNav = {
          position: 'absolute',
          left: '-200px',
          width: '0px',
          transition: 'left 0.3s, width 0.3s',
        };
        responsiveDeviceChild = {
          width: '100%'
        }

        iconMenu = {display: 'block'}
      }



    let back;

      if (allmenu.restaurant_id === 6) {
        back = `url(${require('./assets/Parkview-38.png')})`;
      } else if (allmenu.restaurant_id === 5) {
        back = `url(${require('./assets/WEB_DA-06.jpg')})`;
      } else {
        back = `url(${require('./assets/DSC05941APP4b.jpg')})`;
      }

    return (
      <div className='backgroundlayout' style={{
        flex: 1,
        backgroundSize: 'cover',
        width: '100%',
        marginTop: 10,
        background: 'white'
      }}>
        <div style={styles.content}>
          {/*<button onClick={()=>setOpenNav(!openNav)} style={{...styles.iconMenuMobile, ...iconMenu}}>
            <Menu />
          </button>*/}
          

          <div  className='contentChild' style={{...styles.contentChild, ...responsiveDeviceChild}}>

            <div className='nav-menu-bar' style={{
              background: '#000',
            
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
             
              
              
            }}>
              <Link to={`/${hash}`}>
                
                <img
                style={{
                  width: 100,
              }}
              src={`${constants.pathLogoRestaurant}/${allmenu.logo}`}
              alt="Logo"
              /> 
               </Link>
              
              {showBack ? (
                 <button
                            onClick={() => navigate(-1)}
                        style={{
                    width: 170,
                    height: 52,
                    background: '#FFDA03',
                    display: 'flex',
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: "center",
                    paddingInline: 10,
                    borderRadius: 7,
                    borderColor: '#A6A6A6',
                    border: '1.3px solid #A6A6A6',
                          textDecoration: 'none',
                    marginRight: 10
                    

                  }}>
                    <span style={{ color: '#000', fontSize: 19, fontWeight: 'bold'}}> GO TO BACK</span>
                  </button>
              ): (
                  
              <div style={{ display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',}}>
                    <a href='#' target='_blank'> <img
                    style={{
                      width: 28,
                        height: 28,
                        marginInline: 10
                    
                  }}
                  src={require('./assets/instagram.png')}
                  alt="Logo"
                  /> 
                  </a>

                  <a href='#' target='_blank'> 
                    
                    
                  <img
                    style={{
                        width: 28,
                          height: 28,
                          marginInline: 10
                  }}
                  src={require('./assets/facebook.png')}
                  alt="Logo"
                  /> 
                  </a>
              
                <div>
                  <ChangeLang />
                </div>
              </div>
              )}
              
             


            

            </div>
            {children}

            <div className='content-footer' style={{
              background: '#000',
              padding: 20,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
             
            }}>
             
              <div className='content-footer-ordernow' style={{ display: 'flex', width: '70%', justifyContent: 'center' }}>
                
                {showFooterBack && ( <div>
                   <button
                            onClick={() => navigate(-1)}
                        style={{
                    width: 250,
                  height: 72,
                  background: '#FFDA03',
                  display: 'flex',
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: "center",
                  paddingInline: 10,
                  borderRadius: 7,
                  borderColor: '#A6A6A6',
                  border: '1.3px solid #A6A6A6',
                          textDecoration: 'none',
                          marginBottom: 20,
                  marginInline: 10
                    

                  }}>
                    <span style={{ color: '#000', fontSize: 19, fontWeight: 'bold'}}> GO TO BACK</span>
                  </button>
                </div>)}
               
                <a href='https://lindensportsbarandrest.com' target='_blank' style={{
                  width: 250,
                  height: 72,
                  background: '#FFDA03',
                  display: 'flex',
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: "center",
                  paddingInline: 10,
                  borderRadius: 7,
                  borderColor: '#A6A6A6',
                  marginInline: 10,
                  border: '1.3px solid #A6A6A6',
                  textDecoration: 'none',
                  

                }}>
                  <span style={{ color: '#000', fontSize: 19, fontWeight: 'bold'}}> GO TO WEBSITE</span>
                </a>
              </div>

              <br />


              <div  style={{}}>
                <img
                  style={{
                    width: 162,
                
                    marginRight:20
                  }}
                  src={`${constants.pathLogoRestaurant}/${allmenu.logo}`}
                  alt="Logo"
                /> 

                <div style={{textAlign: 'center', color: 'white', fontSize: 14}}>
                
                   <p>Sales@boostyourmenu.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    );
  };

export default Index;